<template>
    <div class="gray-bg search-list" v-htmltit data-title="搜索列表">
        <go-cart-btn></go-cart-btn>
        <go-back-btn></go-back-btn>
        <div class="list-top-search">
            <div class="search-input">
                <van-field style="padding:0.25rem" v-model="searchVal" clearable placeholder="请输入搜索信息" />
            </div>
            <div class="search-shunxu price"
                :class="shunxu.type == 2?'actived':''" 
                @click="handleClickShunxuPrice(shunxu.price)">价格
                <span class="iconfont icon-shunxu"  :class="shunxu.price == 1?'rotating':''"></span>
            </div>
            <div class="search-shunxu"
                :class="shunxu.type == 1?'actived':''" 
                @click="handleClickZong()"
            >销量</div>
        </div>
        <skeleton v-if="!loading"></skeleton>
        <div v-else style="margin-top:3rem">
            <good-list></good-list>
        </div>
    </div>
</template>
<script>
import './index.less'
import skeleton from "./component/skeleton"
import goodList from "@/view/Public/goodList"
import goCartBtn from "@/view/Public/goCartBtn"
import goBackBtn from "@/view/Public/goBackBtn"

export default {
    name:"search-list",
    components:{skeleton, goodList,goCartBtn,goBackBtn },
    data(){
        return{
            loading:false, //加载成功之前显示骨架页
            shunxu:{
                type:2,  //1 综合  2 价格
                price:2  //1 升序  2 降序
            },
            searchVal:""
        }
    },
    mounted(){
        setTimeout(()=>{
            this.loading = true
        },1000)
        this.searchVal = this.$route.params.search
    },
    methods:{
        handleClickZong(){
            this.shunxu.type = 1
        },
        handleClickShunxuPrice(price){
            this.shunxu.type = 2;
            if(price === 1){
                this.$toast('升序');
                this.shunxu.price = 2;
            }else if(price === 2){
                this.$toast('降序');
                this.shunxu.price = 1;
            }
        },
    }
}
</script>